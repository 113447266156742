<template>
<div class="container">
  <div class="field">
      <input v-if="!show"
       :name="label"
       :id="label"
       :class="{forgotPass: forgotPassword}"
       :type="type"
       :label="label"
       :placeholder="label"
       :value="modelValue"
       @input="$emit('update:modelValue', $event.target.value)"
       :autocomplete="autocomplete"
       >
       <div v-else style="padding: 0; margin: 0">
        <input
        :type="type"
        :label="label"
        name="new-password"
        id="new-password"
        :placeholder="label"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        autocomplete="new-password"
        >
        <i :class="[isEyeOpen ? 'eye icon': 'eye slash icon', 'eye-password']"
        v-if="show" @click="changeEye"></i>
       </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'TextField',
  data () {
    return {
      isEyeOpen: true
    }
  },
  props: {
    type: {
      required: false,
      type: String,
      default: 'text'
    },
    label: {
      required: true,
      type: String
    },
    modelValue: undefined,
    forgotPassword: {
      required: false,
      type: Boolean,
      default: false
    },
    show: {
      required: false,
      type: Boolean,
      default: false
    },
    autocomplete: {
      required: false,
      type: String,
      default: 'on'
    }
  },
  computed: {
    value: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  emits: ['update:modelValue'],
  methods: {
    changeEye () {
      const passwordField = document.querySelector('#new-password')
      this.isEyeOpen = !this.isEyeOpen
      if (passwordField.getAttribute('type') === 'password') {
        passwordField.setAttribute('type', 'text')
      } else {
        passwordField.setAttribute('type', 'password')
      }
    }
  }
}
</script>

<style scoped>
  input {
    border: none !important;
    border-bottom: 1px solid #8f989b !important;
    border-radius: 0 !important;
    width: inherit;
    padding-left: 0 !important;
    padding-right: 0 !important;
    transition: border-bottom .5s ease !important;
    outline: none !important;
    font-size: 1.2rem !important;
  }

  ::placeholder{
    color: gray !important
  }
  .forgotPass{
    text-align: center;
  }
  .forgotPass::placeholder{
    text-align: center;
  }
  input:focus{
    outline: none !important;
  }
  input:hover:not(:focus) {
    border-bottom: 1px solid #000000 !important;
  }
  input:focus,input:not(:placeholder-shown) {
    border-bottom: 1px solid #2b7aaf !important;
  }
  input:hover:not(:placeholder-shown) {
    border-bottom: 1px solid #2b7aaf !important;
  }
  .container {
    width: inherit;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: .5rem;
  }

  .eye-password {
    color:#676565;
    text-shadow: 1px 1px 1px #ccc;
    font-size: 1.5em;
    position: absolute;
    right: 0;
    cursor: pointer;
    margin-top: 1rem;
    align-content: center;
    align-items: center;
  }
</style>
