<template>
  <div>
    <h5 class="my-3 mx-0 text-center">{{ $t('createYourNewPassword') }}</h5>
    <p class="my-0 py-0">{{ $t('auth.fourDigits') }}</p>
    <form
      @submit.prevent
      class="ui form w-100 text-center align-items-center justify-content-center auth-form">

      <TextField
        :model-value="form.password"
        @update:model-value="form.password=$event"
        type="password"
        :label="$t('form.password')"
        />

        <TextField
          style="margin-top:15px"
          :model-value="form.repeatPassword"
          @update:model-value="form.repeatPassword=$event"
          type="password"
          :label="$t('form.repeatPassword')"
          />

        <Button style="margin-top: 15px; margin-bottom: 10px" :text="$t('buttons.set')"
        color="primary"
        @clicked="onSubmit" />

        <div v-if="v$.form.password.$error || v$.form.repeatPassword.$error" class="mb-2">
          <span v-if="v$.form.password.$error" class="text-danger">{{ v$.form.password.$errors[0].$message}}</span>
          <span v-else-if="v$.form.repeatPassword.$error" class="text-danger">{{v$.form.repeatPassword.$errors[0].$message }}</span>
        </div>

        <p class="m-0">
          <router-link style="font-size:inherit !important" to="/auth">{{ $t('auth.loginAsAdmin') }}</router-link>
          <!-- <router-link style="font-size:inherit !important" to="/auth">{{ $t("buttons.siginWithOtherUser") }}</router-link> -->
        </p>
    </form>
  </div>
</template>

<script>
import Button from '@/components/form/Button'
import TextField from '@/components/form/TextField'
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, helpers, numeric } from '@vuelidate/validators/dist/raw.esm'
import globals from '@/utils/globals'

export default {
  name: 'AuthResetPassword',
  components: {
    Button,
    TextField
  },
  setup: () => ({ v$: useVuelidate() }),
  data () {
    return {
      isLoading: false,
      token: null,
      source: null,
      form: {
        password: '',
        repeatPassword: ''
      }
    }
  },
  validations () {
    return {
      form: {
        password: {
          required: helpers.withMessage(this.$t('validations.password.required'), required),
          numeric: helpers.withMessage(this.$t('validations.password.numericOnly'), numeric),
          minLength: helpers.withMessage(this.$t('validations.password.miniumCharacters', { number: globals.passwordRules.minLength }), minLength(globals.passwordRules.minLength))
          // minLength: helpers.withMessage(this.$t('validations.password.miniumCharacters', { number: globals.passwordRules.minLength }), minLength(globals.passwordRules.minLength)),
          // upperCase: helpers.withMessage(this.$t('validations.password.uppercaseCharacters'), (value) => /(?=.*[A-Z])/.test(value)
          // ),
          // numberIn: helpers.withMessage(this.$t('validations.password.numberCharacters'), (value) => /(?=.*\d)/.test(value))
          // specialCharacterIn: helpers.withMessage(this.$t('validations.password.specialCharacters'), (value) => /([!@$%#])/.test(value))
        },
        repeatPassword: {
          required: helpers.withMessage(this.$t('validations.password.repeatRequired'), required),
          sameAs: helpers.withMessage(this.$t('validations.password.match'), function (value) { return this.form.password === value })
        }
      }
    }
  },
  created () {
    this.token = this.$route.params.token
    this.source = Number(this.$route.params.source || 1)
    // console.log(this.source)
  },
  methods: {
    onSubmit () {
      this.v$.form.$touch()
      const formValid = !(this.v$.form.$error)

      if (formValid) {
        const payload = {
          token: this.token,
          password: this.form.password
        }

        if (this.source === 2) {
          // reset password if source is from resident app
          this.$store.dispatch('oauth/resetPasswordUserFromResidentApp', payload).then(() => {
            this.$router.replace({ path: '/auth/mobilePasswordSetted' })
          }).catch(() => { })
        } else {
          // reset password if source is from admin (this.source === 1)
          this.$store.dispatch('oauth/resetPassword', payload).then(() => {
            this.$router.replace({ path: '/' })
          }).catch(() => { })
        }

        // this.$store.dispatch('oauth/resetPassword', payload).then(() => {
        //   if (this.source === 2) {
        //     this.$router.replace({ path: '/auth/mobilePasswordSetted' })
        //   } else {
        //     this.$router.replace({ path: '/' })
        //   }
        // }).catch(() => {
        // })
      }
    }
  }
}
</script>

<style scoped>
h5 {
  font-size: 1.6rem;
}
</style>
